import servikMapJson from './servikMap.json'
export const servikMap = servikMapJson

export const emptySurvey = {
	name: 'Scratch Survey',
	logo_route: null,
	pages: [
		{
			question: {
				text: '',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 1,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 9,
					text: '',
					icon: 'light',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '',
					connectedRoomId: '',
					isLinkServik: false
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		}
	]
}

export const content_survey = {
	name: 'Content Survey',
	logo_route: null,
	pages: [
		{
			question: {
				text: 'Question',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 1,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			submit_email_list: [],
			submit_sms_list: [],
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			content: [
				{
					text: '',
					response: '',
					placeholder: '',
					type: 'text',
				}
			],
			options: [],
			ratings: [],
			type: 'content',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		}
	]
}

export const rating_survey = {
	name: 'Rating Survey',
	logo_route: null,
	pages: [
		{
			question: {
				text: 'Question',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 1,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			content: [],
			options: [],
			ratings: [
				{
					main_text: '',
					value: 0,
					left_text: '',
					right_text: ''
				}
			],
			type: 'rating',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		}
	]
}

export const image_survey = {
	name: 'Image Survey',
	logo_route: null,
	pages: [
		{
			question: {
				text: 'Question',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 1,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			content: [],
			options: [],
			ratings: [],
			type: 'image',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		}
	]
}

export const open_text_survey = {
	name: 'Open Text Survey',
	logo_route: null,
	pages: [
		{
			question: {
				text: 'Question',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 1,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			content: [],
			options: [],
			ratings: [],
			type: 'open_text',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		}
	]
}

export const emptyPage = {
	// The main question of the page
	question: {
		text: '',
		align: '',
		fontSize: 45,
		color: '',
	},
	shape: 'circle',
	delay_time: 1,
	api: false,
	phone_numbers: [],
	// The page number that the submit button will route to (if there is content)
	submit_route_to: '',
	submit_sms_list: [],
	submit_email_list: [],
	// The text of the submit button (if there is content)
	submit_text: '',
	submit_direction: 'right',
	submit_btn_color: '',
	submit_text_color: '',
	// The default rating shape (if the page type is rating)
	rating_shape: 'star',
	// The open text object (if the page type is open_text)
	open_text: {
		text: '',
		align: '',
		fontSize: 20,
		/*
			if isChecklist is true:
				we split the text by new line and show a checkbox next to each line
				for now we only show checkboxes for visual only.
			if isChecklist is false: we show the text as is
		*/
		isChecklist: false,
	},
	/*
		content is an array of objects, where each object has a text (defined by buzzz admin) and a response (text area)
		the goal is to show the text and get the dynamic response (text area) from the user (client)
		when he clicks the submit button:
			1. we direct him to the page number defined in the submit_route_to.
			2. we add the device_code to EACH object.
			3. we loop through the content array and send each object to the server.
	*/
	content: [
		/*
			Object example:
				{
					"text": "Text"
					"response": ""
				}
		*/
	],
	options: [
		{
			phone_numbers: [],
			apis: [],
			servik_id: 9,
			text: '',
			icon: 'light',
			color: '#E6E6E6',
			shape: 'square',
			route_to: '',
			connectedRoomId: '',
			isLinkServik: false
		},
		{
			phone_numbers: [],
			apis: [],
			servik_id: 9,
			text: '',
			icon: 'light',
			color: '#E6E6E6',
			shape: 'square',
			route_to: '',
			connectedRoomId: '',
			isLinkServik: false
		},
		{
			phone_numbers: [],
			apis: [],
			servik_id: 9,
			text: '',
			icon: 'light',
			color: '#E6E6E6',
			shape: 'square',
			route_to: '',
			connectedRoomId: '',
			isLinkServik: false
		},
		{
			phone_numbers: [],
			apis: [],
			servik_id: 9,
			text: '',
			icon: 'light',
			color: '#E6E6E6',
			shape: 'square',
			route_to: '',
			connectedRoomId: '',
			isLinkServik: false
		},
		{
			phone_numbers: [],
			apis: [],
			servik_id: 9,
			text: '',
			icon: 'light',
			color: '#E6E6E6',
			shape: 'square',
			route_to: '',
			connectedRoomId: '',
			isLinkServik: false
		},
		{
			phone_numbers: [],
			apis: [],
			servik_id: 9,
			text: '',
			icon: 'light',
			color: '#E6E6E6',
			shape: 'square',
			route_to: '',
			connectedRoomId: '',
			isLinkServik: false
		},
		{
			phone_numbers: [],
			apis: [],
			servik_id: 9,
			text: '',
			icon: 'light',
			color: '#E6E6E6',
			shape: 'square',
			route_to: '',
			connectedRoomId: '',
			isLinkServik: false
		},
		{
			phone_numbers: [],
			apis: [],
			servik_id: 9,
			text: '',
			icon: 'light',
			color: '#E6E6E6',
			shape: 'square',
			route_to: '',
			connectedRoomId: '',
			isLinkServik: false
		}
	],
	/*
		ratings is an array of objects, where each object has a main_text, value, left_text, and right_text
		the goal is to show the main_text, left_text, and right_text and the ratings with the rating_shape image
		when the user hovers over the rating, we show the colored rating_shape image from 0 to the value.
		when the user leaves the rating, we show the rating_shape image with value 0.
		when the user (client) clicks on a rating:
			we add the device_code to the object and we send the whole object to the server.
	*/
	ratings: [
		/*
			Object example:
				{
					"main_text": "Main Text",
					"left_text": "Left Text",
					"right_text": "Right Text",
					"value": 0
				}
		*/
	],
	// This is the type of the page (rating, open_text, image, button)
	type: 'button',
	/*
		imgInfo is an object that has the url, width, height, and isBackground
		the goal is to show the image with its width and height
		if it is a background image, we show the image as a background on the whole page
	*/
	imgInfo: {
		url: '',
		width: 0,
		height: 0,
		isBackground: false
	}
}

export const default_survey = {
	name: 'Default Survey',
	logo_route: 2,
	pages: [
		{
			question: {
				text: 'הכל בסדר? מה אפשר לשפר?',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 0,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 39,
					text: 'לייק למנקה',
					icon: 'like',
					color: '#008040',
					shape: 'circle',
					route_to: '3',
					idx: 0,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 54,
					text: 'פח מלא',
					icon: 'full_garbage_can',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 1,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 15,
					text: 'חסר סבון',
					icon: 'missing_soap',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 2,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 8,
					text: 'רצפה מלוכלכת',
					icon: 'dirty_floor',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 3,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 7,
					text: 'ריח לא טוב',
					icon: 'bad_smell',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 4,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 9,
					text: 'אסלה מלוכלכת',
					icon: 'dirty_lavatory_seat',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 5,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 16,
					text: 'חסר נייר',
					icon: 'missing_toilet_paper',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 6,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 88,
					text: 'אחזקה',
					icon: 'maintenance',
					color: '#336699',
					shape: 'circle',
					route_to: '1',
					idx: 7,
					connectedRoomId: '',
					isLinkServik: true
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'היכן הבעיה?',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 30,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: ['972544880044'],
					apis: [],
					servik_id: 24,
					text: 'ברזים נוזלים',
					icon: 'problem_fluid_taps',
					color: '#6699cc',
					shape: 'circle',
					route_to: '3',
					idx: 0,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: ['972544880044'],
					apis: [],
					servik_id: 22,
					text: 'סתימה',
					icon: 'problem_blockage',
					color: '#6699cc',
					shape: 'circle',
					route_to: '3',
					idx: 1,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: ['972544880044'],
					apis: [],
					servik_id: 25,
					text: 'בעיית תאורה',
					icon: 'problem_lightning',
					color: '#6699cc',
					shape: 'circle',
					route_to: '3',
					idx: 2,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: ['972544880044'],
					apis: [],
					servik_id: 26,
					text: 'ניאגרה שבורה',
					icon: 'problem_broken_toilet',
					color: '#6699cc',
					shape: 'circle',
					route_to: '3',
					idx: 3,
					connectedRoomId: '',
					isLinkServik: false
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'שלום, אנא בחר משימה',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 30,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 43,
					text: 'אחזקה',
					icon: 'maintenance',
					color: '#003366',
					shape: 'circle',
					route_to: '3',
					idx: 0,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 42,
					text: 'מפקח',
					icon: 'supervise',
					color: '#6699cc',
					shape: 'circle',
					route_to: '3',
					idx: 1,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 48,
					text: 'מנקה',
					icon: 'cleaner_arrived',
					color: '#008040',
					shape: 'circle',
					route_to: '3',
					idx: 2,
					connectedRoomId: '',
					isLinkServik: false
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'תודה רבה על שיתוף הפעולה',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 4,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [],
			content: [],
			ratings: [],
			type: '',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		}
	]
}

export const cleaning_survey = {
	name: 'Cleaning',
	logo_route: 2,
	pages: [
		{
			question: {
				text: '?הכל בסדר? מה אפשר לשפר',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 0,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 39,
					text: 'לייק למנקה',
					icon: 'like',
					color: '#008040',
					shape: 'circle',
					route_to: '3',
					idx: 0,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 8,
					text: 'פח מלא',
					icon: 'dirty_floor',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 1,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 15,
					text: 'חסר סבון',
					icon: 'missing_soap',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 2,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 196,
					text: 'רצפה מלוכלכת',
					icon: 'problem_urinals',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 3,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 7,
					text: 'ריח לא טוב',
					icon: 'bad_smell',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 4,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 9,
					text: 'אסלה מלוכלכת',
					icon: 'dirty_lavatory_seat',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 5,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 16,
					text: 'חסר נייר',
					icon: 'missing_toilet_paper',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 6,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 88,
					text: 'אחזקה',
					icon: 'maintenance',
					color: '#336699',
					shape: 'circle',
					route_to: '1',
					idx: 7,
					connectedRoomId: '',
					isLinkServik: true
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'היכן הבעיה?',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 60,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 24,
					text: 'ברזים נוזלים',
					icon: 'problem_fluid_taps',
					color: '#6699cc',
					shape: 'circle',
					route_to: '3',
					idx: 0,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 22,
					text: 'סתימה',
					icon: 'problem_blockage',
					color: '#6699cc',
					shape: 'circle',
					route_to: '3',
					idx: 1,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 25,
					text: 'בעיית תאורה',
					icon: 'problem_lightning',
					color: '#6699cc',
					shape: 'circle',
					route_to: '3',
					idx: 2,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 26,
					text: 'אסלה שבורה',
					icon: 'problem_broken_toilet',
					color: '#6699cc',
					shape: 'circle',
					route_to: '3',
					idx: 3,
					connectedRoomId: '',
					isLinkServik: false
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'שלום, אנא בחר משימה',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 60,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 43,
					text: 'אחזקה',
					icon: 'maintenance',
					color: '#003366',
					shape: 'circle',
					route_to: '3',
					idx: 0,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 42,
					text: 'מפקח',
					icon: 'supervise',
					color: '#6699cc',
					shape: 'circle',
					route_to: '3',
					idx: 1,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 48,
					text: 'מנקה',
					icon: 'cleaner_arrived',
					color: '#008040',
					shape: 'circle',
					route_to: '3',
					idx: 2,
					connectedRoomId: '',
					isLinkServik: false
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'תודה רבה על שיתוף הפעולה',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 1,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		}
	]
}

export const kitchen_survey = {
	name: 'Kitchen',
	logo_route: 2,
	pages: [
		{
			question: {
				text: '?הכל בסדר? מה אפשר לשפר',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 1,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 39,
					text: 'לייק למנקה',
					icon: 'like',
					color: '#008f00',
					shape: 'circle',
					route_to: '3',
					idx: 0,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 88,
					text: 'אחזקה',
					icon: 'maintenance',
					color: '#0080ff',
					shape: 'circle',
					route_to: '1',
					idx: 1,
					connectedRoomId: '',
					isLinkServik: true
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 8,
					text: 'ניקיון',
					icon: 'dirty_floor',
					color: '#ff2600',
					shape: 'circle',
					route_to: '3',
					idx: 2,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 83,
					text: 'כלים חד פעמי',
					icon: 'disposable_dishes',
					color: '#ff0000',
					shape: 'circle',
					route_to: '3',
					idx: 3,
					connectedRoomId: '',
					isLinkServik: false
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'היכן הבעיה?',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 60,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 82,
					text: 'תמי 4',
					icon: 'water_bar',
					color: '#0080ff',
					shape: 'circle',
					route_to: '3',
					idx: 0,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 22,
					text: 'סתימה',
					icon: 'problem_blockage',
					color: '#0080ff',
					shape: 'circle',
					route_to: '3',
					idx: 1,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 24,
					text: 'ברז נוזל',
					icon: 'problem_fluid_taps',
					color: '#0080ff',
					shape: 'circle',
					route_to: '3',
					idx: 2,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 56,
					text: 'מכשיר חשמל תקול',
					icon: 'maintenance_problem',
					color: '#0080ff',
					shape: 'circle',
					route_to: '3',
					idx: 3,
					connectedRoomId: '',
					isLinkServik: false
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'שלום, אנא בחר משימה',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 60,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 43,
					text: 'אחזקה',
					icon: 'maintenance',
					color: '#0433ff',
					shape: 'circle',
					route_to: '3',
					idx: 0,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 42,
					text: 'מפקח',
					icon: 'supervise',
					color: '#942192',
					shape: 'circle',
					route_to: '3',
					idx: 1,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 48,
					text: 'מנקה',
					icon: 'cleaner_arrived',
					color: '#00ff40',
					shape: 'circle',
					route_to: '3',
					idx: 2,
					connectedRoomId: '',
					isLinkServik: false
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'תודה רבה על שיתוף הפעולה',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 1,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [],
			content: [],
			ratings: [],
			type: '',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		}
	]
}

export const pad_survey = {
	name: 'Pad',
	logo_route: 2,
	pages: [
		{
			question: {
				text: '',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 1,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 39,
					text: 'btn:1',
					icon: 'like',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 0,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 16,
					text: 'btn:2',
					icon: 'missing_toilet_paper',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 1,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 15,
					text: 'Btn:3',
					icon: 'missing_soap',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 2,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 8,
					text: 'Btn:4',
					icon: 'dirty_floor',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 3,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 54,
					text: 'Btn:5',
					icon: 'full_garbage_can',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 4,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 7,
					text: 'Btn:6',
					icon: 'bad_smell',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 5,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 9,
					text: 'Btn:7',
					icon: 'dirty_lavatory_seat',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 6,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 89,
					text: 'Btn:8',
					icon: 'problem_maintenance',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 7,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 48,
					text: 'Btn:9',
					icon: 'cleaner_arrived',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 8,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 55,
					text: 'Btn:10',
					icon: 'maintenance_fixed',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '',
					idx: 9,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 9,
					text: '',
					icon: 'light',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '',
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 42,
					text: 'Btn:12',
					icon: 'supervise_arrivel',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '',
					idx: 11,
					connectedRoomId: '',
					isLinkServik: false
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		}
	]
}

export const pad_kitchen = {
	name: 'Pad Kitchen',
	logo_route: 2,
	pages: [
		{
			question: {
				text: '',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 1,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 39,
					text: 'btn:1',
					icon: 'like',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 0,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 14,
					text: 'btn:2',
					icon: 'missing_paper_towel',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 1,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 80,
					text: 'Btn:3',
					icon: 'general_cleaning',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 2,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 602,
					text: 'Btn:4',
					icon: 'missing_milk',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 3,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 603,
					text: 'Btn:5',
					icon: 'no_sugar_/_coffee',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 4,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 604,
					text: 'Btn:6',
					icon: 'missing_dishes',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 5,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 605,
					text: 'Btn:7',
					icon: 'coffee_/_water_machine',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 6,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 89,
					text: 'Btn:8',
					icon: 'problem_maintenance',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 7,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 48,
					text: 'Btn:9',
					icon: 'cleaner_arrived',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '0',
					idx: 8,
					connectedRoomId: '',
					isLinkServik: false
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 43,
					text: 'Btn:10',
					icon: 'maintenance_arrivel',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '',
					idx: 9,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 9,
					text: '',
					icon: 'light',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '',
					connectedRoomId: '',
					isLinkServik: false,
					idx: 10
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 42,
					text: 'Btn:12',
					icon: 'supervise_arrivel',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '',
					idx: 11,
					connectedRoomId: '',
					isLinkServik: false
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		}
	]
}

export const splitted_survey = {
	name: 'Split',
	logo_route: 2,
	pages: [
		{
			question: {
				text: 'בחר מצב חדר שירותים',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'transparent-circle',
			delay_time: 1,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 94,
					text: 'נשים',
					icon: 'room_women_+_link',
					color: 'transparent',
					shape: 'transparent-circle',
					route_to: '3',
					idx: 0,
					isLinkServik: true,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 95,
					text: 'נשים',
					icon: 'room_women_-_link',
					color: 'transparent',
					shape: 'transparent-circle',
					route_to: '1',
					idx: 1,
					isLinkServik: true,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 92,
					text: 'גברים',
					icon: 'room_men_+_link',
					color: 'transparent',
					shape: 'transparent-circle',
					route_to: '3',
					idx: 2,
					isLinkServik: true,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 93,
					text: 'גברים',
					icon: 'room_men_-_link',
					color: 'transparent',
					shape: 'transparent-circle',
					route_to: '1',
					idx: 3,
					isLinkServik: true,
					connectedRoomId: ''
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: ' ?מה אפשר לשפר',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'rounded',
			delay_time: 30,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 54,
					text: 'פח מלא',
					icon: 'full_garbage_can',
					color: '#ff2600',
					shape: 'rounded',
					route_to: '3',
					idx: 0,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 15,
					text: 'חסר סבון',
					icon: 'missing_soap',
					color: '#ff2600',
					shape: 'rounded',
					route_to: '3',
					idx: 1,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 8,
					text: 'רצפה מלוכלכת',
					icon: 'dirty_floor',
					color: '#ff2600',
					shape: 'rounded',
					route_to: '3',
					idx: 2,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 7,
					text: 'ריח לא טוב',
					icon: 'bad_smell',
					color: '#ff2600',
					shape: 'rounded',
					route_to: '3',
					idx: 3,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 9,
					text: 'אסלה מלוכלכת',
					icon: 'dirty_lavatory_seat',
					color: '#ff2600',
					shape: 'rounded',
					route_to: '3',
					idx: 4,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 16,
					text: 'חסר נייר',
					icon: 'missing_toilet_paper',
					color: '#ff2600',
					shape: 'rounded',
					route_to: '3',
					idx: 5,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 14,
					text: 'חסר נייר ידיים',
					icon: 'missing_paper_towel',
					color: '#ff2600',
					shape: 'rounded',
					route_to: '3',
					idx: 6,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 88,
					text: 'אחזקה',
					icon: 'maintenance_link',
					color: '#6699cc',
					shape: 'rounded',
					route_to: '4',
					idx: 7,
					isLinkServik: true,
					connectedRoomId: ''
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'שלום, אנא בחר משימה',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'rounded',
			delay_time: 30,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 43,
					text: 'אחזקה',
					icon: 'maintenance_arrivel',
					color: '#003366',
					shape: 'rounded',
					route_to: '3',
					idx: 0,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 42,
					text: 'מפקח',
					icon: 'supervise_arrivel',
					color: '#6699cc',
					shape: 'rounded',
					route_to: '3',
					idx: 1,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 48,
					text: 'מנקה',
					icon: 'cleaner_arrived',
					color: '#008040',
					shape: 'rounded',
					route_to: '3',
					idx: 2,
					isLinkServik: false,
					connectedRoomId: ''
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'תודה רבה על שיתוף הפעולה',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 4,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [],
			content: [],
			ratings: [],
			type: '',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'היכן הבעיה',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'rounded',
			delay_time: 30,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 24,
					text: 'ברזים נוזלים',
					icon: 'problem_fluid_taps',
					color: '#6699cc',
					shape: 'rounded',
					route_to: '3',
					idx: 0,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 22,
					text: 'סתימה',
					icon: 'problem_blockage',
					color: '#6699cc',
					shape: 'rounded',
					route_to: '3',
					idx: 1,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 25,
					text: 'בעיית תאורה',
					icon: 'problem_lightning',
					color: '#6699cc',
					shape: 'rounded',
					route_to: '3',
					idx: 2,
					isLinkServik: false,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 26,
					text: 'ניאגרה שבורה',
					icon: 'problem_broken_toilet',
					color: '#6699cc',
					shape: 'rounded',
					route_to: '3',
					idx: 3,
					isLinkServik: false,
					connectedRoomId: ''
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		},
		{
			question: {
				text: 'שלום, אנא בחר חדר',
				align: '',
				fontSize: 45,
				color: '',
			},
			shape: 'circle',
			delay_time: 30,
			api: false,
			phone_numbers: [],
			submit_route_to: '',
			submit_text: '',
			submit_direction: 'right',
			submit_btn_color: '',
			submit_text_color: '',
			rating_shape: 'star',
			open_text: {
				text: '',
				align: '',
				fontSize: 20,
				isChecklist: false,
			},
			options: [
				{
					phone_numbers: [],
					apis: [],
					servik_id: 91,
					text: 'נשים',
					icon: 'cleaner_link',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '2',
					idx: 0,
					isLinkServik: true,
					connectedRoomId: ''
				},
				{
					phone_numbers: [],
					apis: [],
					servik_id: 91,
					text: 'גברים',
					icon: 'cleaner_link',
					color: '#E6E6E6',
					shape: 'square',
					route_to: '2',
					idx: 1,
					isLinkServik: true,
					connectedRoomId: ''
				}
			],
			content: [],
			ratings: [],
			type: 'button',
			imgInfo: {
				url: '',
				width: 0,
				height: 0,
				isBackground: false
			}
		}
	]
}

export const surveyTemplates = {
	default_survey,
	cleaning_survey,
	kitchen_survey,
	pad_survey,
	splitted_survey,
	pad_kitchen,
	content_survey,
	rating_survey,
	image_survey,
	open_text_survey
}
